<template>
    <div class="nav_text" style="position: relative;">
            <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="backIndex()"/>
        </div>
        <!-- 下拉选择条件 -->
<van-dropdown-menu>
  <van-dropdown-item v-model="value1" :options="option1" @change="changeSelected" />
</van-dropdown-menu>

<!-- 表格具体数据 -->
<el-table :data="tableData" style="width: 100%" stripe >
    <el-table-column prop="areaName" label="区域名称"></el-table-column>
    <el-table-column prop="projectName" label="项目名称"></el-table-column>
    <el-table-column prop="subStationName" label="变电所名称"></el-table-column>
    <el-table-column prop="chargingStationName" label="配电箱名称"></el-table-column>
    <el-table-column prop="meterName" label="电表名称"></el-table-column>
    <el-table-column prop="userName" label="操作人员"></el-table-column>
    <el-table-column prop="connectLoadDate" label="绑定时间" :formatter="formatDate"></el-table-column>
    <el-table-column prop="removeLoadDate" label="解绑时间" :formatter="formatDate"></el-table-column>
    <el-table-column prop="sumValue" label="统计能耗值" width="100">
        <template v-slot="scope">
        <el-tag
          type="primary"
          disable-transitions>{{scope.row.sumValue}}</el-tag>
      </template>
    </el-table-column>
    
  </el-table>
</template>

<script>
import { showNotify  } from 'vant';
import axiosAPI from '../axiosInstance'
import { ElTable, ElTableColumn } from 'element-plus';
export default {
    name: 'ConnectHistory',
    components: {
        ElTable,
        ElTableColumn
    },
    data() {
        return {
            title:'历史记录',
            option1 : [
                { text: '全部分类', value: 0 },
                { text: '人员', value: 1 },
                { text: '配电柜', value: 2 },
            ],
            value1: 0,
            tableData: [],
            connectInfo: {
                //meterCode: '',
                ChargingStationSN: '',
                type:0
          },
        }
    },
    created(){
        this.changeSelected();
    },
    methods: {
        formatDate(row, column) {
    // 获取单元格数据
    let data = row[column.property]
    if (data == null) {
        return null
    }
    return data.replace(/T/g, ' ').split('.')[0];
},
        changeSelected() {
            console.log(this.value1);
           // this.connectInfo.meterCode = sessionStorage.getItem('oneResult');
            this.connectInfo.ChargingStationSN = sessionStorage.getItem('twoResult');
            this.connectInfo.type = this.value1;
            console.log(this.connectInfo);
            if (this.connectInfo.ChargingStationSN == '' || this.connectInfo.ChargingStationSN == null || this.connectInfo.ChargingStationSN == undefined) {
                showNotify({ type: 'danger', message: '请先扫描配电箱二维码！' });
                setTimeout(() => {
                    this.$router.go(-1);
                    // 清空sessionStorage内容
                    sessionStorage.clear();
                }, 2000);
            } else {
                axiosAPI({
                    method: 'put',
                    url: 'https://cz.methodmore.com:8099/MeterDataReport/SearchHistoryEnergy',
                    data: this.connectInfo
                }).then(response => {
                    console.log(response);
                    if (response.data.code == 200) {
                        this.tableData = response.data.result;
                        showNotify({ type: 'success', message: '查询成功' });
                    } else {
                        this.tableData = response.data.result;
                        showNotify({ type: 'danger', message: response.data.content });
                    }
                })
            }
        },
        backIndex() {
            this.$router.push('/');
                    // 清空sessionStorage内容
                    sessionStorage.clear();
        }
    }
}
</script>

<style scoped>
</style>
