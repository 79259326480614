import { createApp } from 'vue'
import App from './App.vue'
import elementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// iconfont
import '@/assets/font/iconfont.css'
import '@/assets/weui.min.css'
import router from './router'
import vant from 'vant'
import 'vant/lib/index.css'
import { Dialog } from 'vant';
import axios from './axiosInstance.js'
import wx from 'weixin-js-sdk'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(elementPlus)
app.use(wx)
app.use(router)
app.use(vant)
app.use(Dialog);
app.mount('#app')
app.config.globalProperties.$axios=axios; 
