<template>
<div class="title">
        <h2>注册用户</h2>
      </div>
      <div class="box">
        <div style="height: 0px; width: 0px; display: none">
          <input type="hidden" id="openid" value="@ViewBag.openid" />
        </div>
        <div
          role="alert"
          class="weui-toptips weui-toptips_warn"
          id="topTips1"
          style="display: none; opacity: 1"
        >
          内容不可以为空
        </div>
        <div
          role="alert"
          class="weui-toptips weui-toptips_warn"
          id="topTips2"
          style="display: none; opacity: 1"
        >
          手机号格式错误
        </div>
        <div
          role="alert"
          class="weui-toptips weui-toptips_warn"
          id="topTips3"
          style="display: none; opacity: 1"
        ></div>
        <div class="userMsg">
          <div class="username">
            <input
              type="text"
              placeholder="用户名"
              v-model="userName"
            />
            <i class="iconfont loginIcon">&#xe683;</i>
          </div>
          <div class="password">
            <input
              placeholder="密码"
              :type="passwordType"
              v-model="passWord"
            />
            <i class="iconfont loginIcon" @click="changeIcon()" v-if="pwdFlag">&#xe676;</i>
            <i class="iconfont loginIcon" @click="changeIcon()" v-else>&#xe682;</i>
          </div>
        </div>
        <div class="submitMsg">
          <!-- <div class="page__bd page__bd_spacing"> -->
          <a
            href="javascript:"
            role="button"
            class="confirmUserInfo"
            id="showToast"
            @click="registerUser()"
            >注册</a
          >
        </div>
        <div class="other">
          <a href="#" class="register" @click="Back()">返回登录</a>
        </div>
        <!--BEGIN toast-->
        <div role="alert" id="toast" style="display: none">
          <div class="weui-mask_transparent"></div>
          <div class="weui-toast">
            <i class="weui-icon-success-no-circle weui-icon_toast"></i>
            <p class="weui-toast__content">已完成</p>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name: 'RegisterView',
    data: () => {
        return {
            userId: "osaJT6bUEqq6Bu9zNpOtuu7jgarM",
          userName: "",
          passWord: "",
          pwdFlag: true,
          passwordType:'password'
        }
    },
    methods: {
        Back() {
      this.$router.push('/');
      },
        changeIcon(){
            this.pwdFlag = !this.pwdFlag;
            this.passwordType=this.pwdFlag===true?'password':'text';
            console.log(this.pwdFlag);
      },
      registerUser() {
        console.log(this.userName,this.passWord)
      }
    }
}
</script>


<style scoped>
body{
    width: 100%;
    min-width: 280px;
    max-width: 640px;
    margin: 0 auto;
    font-size: 14px;
    font-family: -apple-system, Helvetica, sans-serif;
    /* color: #666; */
    line-height: 1.5;
}
.title h2{
    font-weight: 400;
    background-color: #FBFBFB;
    margin: 1.5rem;
    text-align: center;
}
.content{
    height: 22vh;
    margin-bottom: 10px;
}
.metername,
.loadname,
.ele,
.remainEle
{
    height: 8vh;
    margin-left: 2.5rem;
    margin-bottom: 1rem;
}
.metername label,
.loadname label{
    display: block;
    margin: 10px 0;
}
.recharge{
    height: 30vh;
    margin-left: 2.5rem;
}
.recharge h3{
    font-weight: 400;
    margin-top: 1rem;
}
.sname select,
.ele select{
    display: block;
    width: 90%;
    height: 5vh;
    border: 1.5px solid #ccc;
    border-radius: 8px;
}
/* .chooseMoney{
    width: 100%;
    height: 100px;
} */
.chooseMoney ul{
    float: left;
    padding-left: 2rem;
    margin-bottom: 1.6rem;
    margin-top: 1.5rem;
}
.chooseMoney ul li{
    list-style: none;
    float: left;
    width: 4rem!important;
    height: 6vh;
    margin:0 5vh 2.5vh 0;
    cursor: pointer;
    line-height:6vh;
    text-align: center;
}
.chooseMoney .confirmMoneybtn{
    background-color: #F2F2F2;
    color: #06AE56;
    font-weight: 800;
    border-radius: 8px;
}
.rechargeMoney{
    width: 90%;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #ccc;
}
.rechargeMoney .icon{
    font-weight: 700;
    font-size: 16px;
}
.meterscan input,
.loadscan input,
.rechargeMoney input{
    border:1px solid #ccc;
    width: 85%;
    height: 5vh;
    border-radius: 1rem;
    padding-left: 10px;
    font-size: 16px;
}

.recharge_btn{
    margin: 4rem 2.5rem 3rem;
}
.weui-btn{
    width: 300px!important;
}

/* 用户维护样式 */
.userMsg{
    height: 20vh;
    text-align: center;
}
.userMsg label{
    display: block;
    font-size: 14px;
}
.userMsg input{
    -webkit-appearance: none;
         -moz-appearance: none;
         appearance:none ;
    /* display: inline-block; */
    width: 50%;
    height: 5vh;
    margin: 20px 0;
    border-radius: 15px;
    border: 1px solid #ccc;
    padding-left: 7px;
    outline: 0;
    box-shadow:0 0 5px 2px rgba(0,0,0,0.1);
    
}
.phone{
    margin-top: 20px;
}
.submitMsg{
    border-radius: 8px;
    /* margin-left: -7px; */
    background-color: #F2F2F2;
    width: 160px;
    margin: auto;
}
.submitMsg .confirmUserInfo{
    color: #06AE56;
    position: relative;
    display: block;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 24px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    text-decoration: none;
    line-height: 1.88235294;
    border-radius: 8px;
    margin-top: 5vh;
}
.other{
    font-weight: 700;
    text-align: center;
    margin-top: 5vh;
}
.other a{
    color: #ccc;
}
.other a:hover{
    color: blue;
}
.box{
    margin-top: 20%;
}
.username,
.password,
.meterscan,
.loadscan{
    position: relative;
}
.loginIcon{
    font-size: 20px;
    color: #ccc;
    position: absolute;
    top: 29%;
    right: 26%;
    z-index: 2; 
}
.viewIcon{
    font-size: 20px;
    color: #ccc;
    position: absolute;
    top: 7%;
    right: 15%;
    z-index: 2; 
}
</style>
