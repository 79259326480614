import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import ScanCodePage from '../views/ScanCodePage.vue'
import ScanCode from '../views/ScanCode.vue'
import ConnectInfo from '../views/ConnectInfo.vue'
import ConnectHistory from '../views/ConnectHistory.vue'
import ExistProjectView from '../views/ExistProjectView.vue'
import CreateProjectView from '../views/CreateProjectView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'LoginView',
  //   component: LoginView
  // },
  // 传递单个参数值
  // {
  //   path: '/Index/:meterName',
  //   name: 'IndexView',
  //   component: IndexView,
  //   props:true
  // },
  {
    path: '/',
    name: 'IndexView',
    component: IndexView,
  },
  {
    path: '/Exist',
    name: 'ExistProjectView',
    component: ExistProjectView
  },
  {
    path: '/Create',
    name: 'CreateProjectView',
    component: CreateProjectView
  },
  {
    path: '/Register',
    name: 'RegisterView',
    component: RegisterView
  },
  {
    path: '/ScanCodePage',
    name: 'ScanCodePage',
    component: ScanCodePage
  },
  {
    path: '/ScanCode',
    name: 'ScanCode',
    component: ScanCode
  },
  {
    path: '/ConnectInfo',
    name: 'ConnectInfo',
    component: ConnectInfo
  },
  {
    path: '/ConnectHistory',
    name: 'ConnectHistory',
    component: ConnectHistory
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
