<template>
  <router-link to="/Index"></router-link>
  <router-view></router-view>
  <!-- <LoginView></LoginView> -->
</template>

<script>
// import LoginView from './views/LoginView.vue'

export default {
  name: 'App',
  data: ()=>{
    return {
      
    }
  },
  // components:{
  //   LoginView
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}
.icon,
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
