<template>
  <div class="nav_text" style="position: relative">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="$router.go(-1)"
    />
  </div>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field
        v-model="confirmInfo.projectName"
        name="项目名称"
        label="项目名称"
        placeholder="项目名称"
      />
      <van-field
        v-model="confirmInfo.userName"
        name="操作人员"
        label="操作人员"
        placeholder="操作人员"
      />
      <van-field
        v-model="confirmInfo.areaName"
        name="区域名称"
        label="区域名称"
        placeholder="区域名称"
      />
      <van-field
        v-model="confirmInfo.subStationName"
        name="变电所名称"
        label="变电所名称"
        placeholder="变电所名称"
      />
      <van-field
        v-model="confirmInfo.chargingStationName"
        name="配电柜名称"
        label="配电柜名称"
        placeholder="配电柜名称"
      />
      <van-field
        v-model="confirmInfo.loadName"
        name="设备名称"
        label="设备名称"
        placeholder="设备名称"
      />
      <van-field
            v-model="confirmInfo.electricEnergy"
            name="电表值"
            label="电表值"
            placeholder="电表值"
          />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="变电所电流信息" name="1">
          <!--<van-field
            v-model="confirmInfo.meterSN"
            name="电表序号"
            label="电表序号"
            placeholder="电表序号"
          />
          <van-field
            v-model="confirmInfo.meterName"
            name="电表名称"
            label="电表名称"
            placeholder="电表名称"
          />-->
           <van-field
            v-model="confirmInfo.aPphaseCurrentTotal"
            name="电表A相额定电流"
            label="电表A相额定电流"
            placeholder="电表A相额定电流"
            label-width="120px"
          />
          <van-field
            v-model="confirmInfo.aPphaseCurrent"
            name="电表A相实时电流"
            label="电表A相实时电流"
            placeholder="电表A相实时电流"
            label-width="120px"
          />
          <van-field
            v-model="confirmInfo.bPphaseCurrentTotal"
            name="电表B相额定电流"
            label="电表B相额定电流"
            placeholder="电表B相额定电流"
            label-width="120px"
          />
          <van-field
            v-model="confirmInfo.bPphaseCurrent"
            name="电表B相实时电流"
            label="电表B相实时电流"
            placeholder="电表B相实时电流"
            label-width="120px"
          />
          <van-field
            v-model="confirmInfo.cPphaseCurrentTotal"
            name="电表C相额定电流"
            label="电表C相额定电流"
            placeholder="电表C相额定电流"
            label-width="120px"
          />
          <van-field
            v-model="confirmInfo.cPphaseCurrent"
            name="电表C相实时电流"
            label="电表C相实时电流"
            placeholder="电表C相实时电流"
            label-width="120px"
          />
        </van-collapse-item>
        <!-- <van-collapse-item title="负载信息" name="2">
          <van-field
            v-model="form.loadName"
            name="负载名称"
            label="负载名称"
            placeholder="负载名称"
          />
          <van-field
            v-model="form.loadEleRule"
            name="负载电流"
            label="负载电流"
            placeholder="负载电流"
          />
          <van-field
            v-model="form.loadVolRule"
            name="负载电压"
            label="负载电压"
            placeholder="负载电压"
          />
        </van-collapse-item> -->
      </van-collapse>
    </van-cell-group>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        v-if="this.confirmInfo.Type == 1"
      >
        确认绑定信息
      </van-button>
      <van-button round block type="primary" native-type="submit" v-else>
        确认解绑信息
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { showNotify } from "vant";
import axiosAPI from "../axiosInstance";
export default {
  name: "ConnectInfo",
  data() {
    return {
      title: "详细信息",
      form: {
        bindingInfo: {
          projectMaster: {},
          user: {},
          projectArea: {},
          projectLoad: {},
          chargingStation: {},
          meterBaseInfo: {},
        },
        meterDataDetail: {},
      },
      connectInfo: {},
      activeNames: ["1"],
      confirmInfo:{}
    };
  },
  created() {
    const paramsString = this.$route.query.params;
    if (paramsString) {
      this.confirmInfo = JSON.parse(paramsString);
      // 现在你可以使用params对象了
    }
    console.log("接收到的信息", this.confirmInfo);
    //this.getConfigInfo();
  },
  methods: {
    getConfigInfo() {
      //this.connectInfo.meterCode = sessionStorage.getItem("oneResult");
      //this.connectInfo.loadCode = sessionStorage.getItem("twoResult");
      console.log(this.connectInfo);
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/ProjectConfig/SearchProjectConfigByScanCode",
        params: { wiringDisassemblyBinding: this.confirmInfo },
      }).then((response) => {
        console.log(response);
        if (response.data.code == 200) {
          console.log(response);
          this.form = response.data.result;
        } else {
          console.log(response);
          showNotify({ type: "danger", message: response.data.content });
          this.$router.go(-1);
        }
      });
    },
    onSubmit() {
      axiosAPI({
        method: "post",
        url: "https://cz.methodmore.com:8099/BindingInfo/InsertEnergyLoadInfoByScanCode",
        data: this.confirmInfo,
      }).then((response) => {
        console.log('确认绑定的结果：',response);
        if (response.data.code == 200&&response.data.content=='success') {
            showNotify({ type: "success", message: "操作成功" });
            this.$router.push('/');
            // 清空sessionStorage内容
            sessionStorage.clear();
        } else {
          showNotify({
            type: "danger",
            message: response.data.content + "请重新绑定",
          });
          this.$router.push('/');
            // 清空sessionStorage内容
            sessionStorage.clear();
        }
      });
    },
  },
};
</script>

<style scoped></style>
