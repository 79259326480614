<template>
  <div class="title">
    <h2>项目工单</h2>
  </div>
  <div class="content">
    <div style="height: 0px; width: 0px; display: none">
      <input type="hidden" id="openid" value="@ViewBag.openid" />
      <input type="hidden" id="payurl" value="@ViewBag.payurl" />
    </div>
    <h3 style="margin-left: 5px" v-show="!selectStatus">
      请先选择要进行操作的工单！
    </h3>
    <van-button
      type="success"
      icon="plus"
      v-if="selectStatus == false"
      size="small"
      @click="addProject()"
      >增加项目工单信息</van-button
    >
    <van-dialog
      v-model:show="showDialog"
      title="新增项目工单信息"
      show-cancel-button
      @confirm="onSubmit"
    >
      <van-form>
        <van-cell-group inset>
          <van-field
            v-model="form.projectName"
            name="项目名称"
            label="项目名称"
            placeholder="项目名称"
            required
            :rules="[{ required: true, message: '请填写项目名称' }]"
          />
          <van-field
            v-model="form.startTime"
            is-link
            readonly
            required
            name="datePicker"
            label="预期开工时间"
            placeholder="点击选择时间"
            @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-date-picker
              @confirm="onConfirmStart"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            v-model="form.endTime"
            is-link
            readonly
            name="datePicker"
            required
            label="预期完成时间"
            placeholder="点击选择时间"
            @click="showPicker1 = true"
          />
          <van-popup v-model:show="showPicker1" position="bottom">
            <van-date-picker
              @confirm="onConfirmEnd"
              @cancel="showPicker1 = false"
            />
          </van-popup>
          <van-field name="isEnable" label="是否启用" required>
            <template #input>
              <van-radio-group v-model="form.isEnable" direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
      </van-form>
    </van-dialog>
    <div class="scanInfo">
      <van-form @submit="onSubmit" v-if="selectStatus == false">
        <van-cell-group inset>
          <van-field
            v-model="configProjectName"
            is-link
            readonly
            name="picker"
            label="项目"
            placeholder="点击选择项目"
            @click="showProject = true"
          />
          <van-popup v-model:show="showProject" position="bottom">
            <van-picker
              :columns="projectColumns"
              :columns-field-names="customFieldName"
              @confirm="onConfirmProject"
              @cancel="showProject = false"
            />
          </van-popup>
          <van-field
            v-model="configAreaName"
            is-link
            readonly
            name="picker"
            label="区域"
            placeholder="点击选择区域"
            @click="showArea = true"
          />
          <van-popup v-model:show="showArea" position="bottom">
            <van-picker
              :columns="areaColumns"
              :columns-field-names="customFieldNameArea"
              @confirm="onConfirmArea"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            v-model="configChargingStationName"
            is-link
            readonly
            name="picker"
            label="配电柜"
            placeholder="点击选择配电柜"
            @click="showChargingStation = true"
          />
          <van-popup v-model:show="showChargingStation" position="bottom">
            <van-picker
              :columns="chargingStationColumns"
              :columns-field-names="customFieldNameChargingStation"
              @confirm="onConfirmChargingStation"
              @cancel="showChargingStation = false"
            />
          </van-popup>
          <van-field
            v-model="configLoadName"
            is-link
            readonly
            name="picker"
            label="设备"
            placeholder="点击选择设备"
            @click="showLoad = true"
          />
          <van-popup v-model:show="showLoad" position="bottom">
            <van-picker
              :columns="loadColumns"
              :columns-field-names="customFieldNameLoad"
              @confirm="onConfirmLoad"
              @cancel="showLoad = false"
            />
          </van-popup>
        </van-cell-group>
      </van-form>
      <div class="metername">
        <label style="font-size: 14px">电表</label>
        <div class="meterscan">
          <input
            type="text"
            id="meterNameScan"
            placeholder="扫描二维码"
            v-model="configInfo.MeterCode"
          />
          <i class="iconfont viewIcon" @click="scanCode()">&#xe67e;</i>
        </div>
      </div>
      <!-- <div class="loadname">
          <label>负载:</label>
          <div class="loadscan">
            
            <input type="text" id="loadName" placeholder="扫描二维码" v-model="projectInfo.loadName"/>
            <i class="iconfont viewIcon" @click="scanCodeTwo()">&#xe67e;</i>
          </div>
        </div> -->
      <!-- <div style="text-align: right">
        <a href="/ConnectHistory">历史记录查询</a>
      </div> -->
    </div>
  </div>

  <div class="btn">
    <a
      href="javascript:"
      role="button"
      class="recharge_btn weui-btn weui-btn_primary"
      v-on:click="connctReport()"
      >确认绑定</a
    >
    <a
      href="javascript:"
      role="button"
      class="recharge_btn weui-btn weui-btn_primary"
      v-on:click="removeReport()"
      >解除绑定</a
    >
  </div>
</template>

<script>
import {
  showNotify,
  Button,
  Dialog,
  showDialog,
  showConfirmDialog,
  Form,
  Field,
  CellGroup,
  RadioGroup,
  Radio,
  showSuccessToast,
} from "vant";
import axiosAPI from "../axiosInstance";
import { ElTable, ElTableColumn } from "element-plus";
export default {
  name: "CreateProjectView",
  data: () => {
    return {
      input1: "",
      userName: "",
      projectInfo: {
        meterName: "",
        loadName: "",
      },
      nonceStr: "",
      timestamp: "",
      signature: "",
      tableData: [
        {
          projectMaster: { projectName: '军工项目1' },
          projectArea: { areaName: '东区1' },
          chargingStation: { chargingStationName: '动力10分柜' },
          projectLoad: { loadName: '焊接机1' },
          user: { userName: '李思罗' },
          bindStatus:0
        },
        {
          projectMaster: { projectName: '军工项目2' },
          projectArea: { areaName: '东区1' },
          chargingStation: { chargingStationName: '动力9分柜' },
          projectLoad: { loadName: '焊接机2' },
          user: { userName: '汪立森' },
          bindStatus:0
        },
      ],
      currentRow: "",
      selectStatus: false,
      showDialog: false,
      form: {
        projectName: "",
        startTime: "",
        endTime: "",
        isEnable: "1",
      },
      showPicker: false,
      showPicker1: false,
      //绑定数据
      //项目
      configProjectName: "",
      showProject: false,
      projectColumns: [],
      customFieldName: {
        text: "projectName",
        value: "id",
      },
      //区域
      configAreaName: "",
      showArea: false,
      areaColumns: [],
      customFieldNameArea: {
        text: "areaName",
        value: "id",
      },
      //配电柜
      configChargingStationName: "",
      showChargingStation: false,
      chargingStationColumns: [],
      customFieldNameChargingStation: {
        text: "chargingStationName",
        value: "id",
      },
      //设备
      configLoadName: "",
      showLoad: false,
      loadColumns: [],
      customFieldNameLoad: {
        text: "loadName",
        value: "id",
      },
      //项目工程配置
      configInfo: {
        ProjectId: 0,
        AreaId: 0,
        ChargingStationId: 0,
        LoadId: 0,
        MeterCode: "",
        Coefficient: 0,
        Type: 1,
      },
    };
  },
  created() {
    //console.log('用户名', this.$route.query.userName); //{id: '123'}
    //console.log('电表名', this.$route.query.meterName); //{id: '123'}
    console.log('电表系数', this.$route.query.coefficient); //{id: '123'}
    //sessionStorage.setItem('userName', this.$route.query.userName);
    //localStorage.setItem('loadName', this.$route.query.loadName);
    //this.meterName = localStorage.getItem('meterName');
    //this.loadName = localStorage.getItem('loadName');
    // this.loadName = this.$route.query.loadName;
    // console.log(this.meterName)
    // console.log(this.loadName)
    this.configProjectName = sessionStorage.getItem("configProjectName");
    this.configAreaName = sessionStorage.getItem("areaName");
    this.configChargingStationName = sessionStorage.getItem("chargingStationName");
    this.configLoadName = sessionStorage.getItem("loadName");
    this.configInfo.Coefficient = this.$route.query.coefficient;
    this.configInfo.MeterCode = sessionStorage.getItem("twoResult");
    console.log('项目工程配置信息', this.configInfo);
      // 现在你可以使用params对象了
    //this.userName = sessionStorage.getItem('userName');
    // this.loadName =sessionStorage.getItem('twoResult').split(',')[0];
    this.getOptionsData();
  },
  onMounted() {},
  methods: {
    select(selection, row) {
      console.log(row);
      if (selection.length > 0) {
        this.selectStatus = true;
      } else {
        this.selectStatus = false;
      }
      this.currentRow = row.id;
      // 清除 所有勾选项
      this.$refs.multipleTable.clearSelection();
      // 当表格数据都没有被勾选的时候 就返回
      // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    addProject() {
      this.showDialog = true;
    },
    onConfirmStart({ selectedValues }) {
      this.form.startTime = selectedValues.join("-");
      this.showPicker = false;
    },
    onConfirmEnd({ selectedValues }) {
      this.form.endTime = selectedValues.join("-");
      this.showPicker1 = false;
    },
    onSubmit() {
      console.log("submit", this.form);
      axiosAPI({
        method: "post",
        url: "https://cz.methodmore.com:8099/projectMaster/InsertProjectMasterWechat",
        data: this.form,
      }).then((response) => {
        console.log(response);
        showSuccessToast("添加成功");
      });
    },
    //绑定信息确认
    getOptionsData() {
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/projectConfig/SearchProjectConfigAndOtherAllWechat",
      }).then((response) => {
        console.log("项目工程配置", response);
        this.tableData = response.data.result;
      });
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/ProjectMaster/SearchProjectMasterAllWechat",
      }).then((response) => {
        console.log("项目信息", response);
        this.projectColumns = response.data.result;
      });
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/ProjectArea/SearchBottomLayerAllWechat",
      }).then((response) => {
        console.log("区域信息", response);
        this.areaColumns = response.data.result;
      });
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/ChargingStation/SearchBottomLayerAllWechat",
      }).then((response) => {
        console.log("配电柜信息", response);
        this.chargingStationColumns = response.data.result;
      });
      axiosAPI({
        method: "get",
        url: "https://cz.methodmore.com:8099/ProjectLoad/SearchProjectLoadAllWechat",
      }).then((response) => {
        console.log("设备信息", response);
        this.loadColumns = response.data.result;
      });
    },
    onConfirmProject({ selectedOptions }) {
      this.configProjectName = selectedOptions[0]?.projectName;
      sessionStorage.setItem("configProjectName", selectedOptions[0]?.projectName);
      this.configInfo.ProjectId = selectedOptions[0]?.id;
      this.showProject = false;
    },
    onConfirmArea({ selectedOptions }) {
      this.configAreaName = selectedOptions[0]?.areaName;
      sessionStorage.setItem("areaName", selectedOptions[0]?.areaName);
      this.configInfo.AreaId = selectedOptions[0]?.id;
      this.showArea = false;
    },
    onConfirmChargingStation({ selectedOptions }) {
      this.configChargingStationName = selectedOptions[0]?.chargingStationName;
      sessionStorage.setItem("chargingStationName", selectedOptions[0]?.chargingStationName);
      this.configInfo.ChargingStationId = selectedOptions[0]?.id;
      this.showChargingStation = false;
    },
    onConfirmLoad({ selectedOptions }) {
      this.configLoadName = selectedOptions[0]?.loadName;
      sessionStorage.setItem("loadName", selectedOptions[0]?.loadName);
      this.configInfo.LoadId = selectedOptions[0]?.id;
      this.showLoad = false;
    },
    scanCode() {
      this.$router.push("/ScanCode");
      // const queryParams = { params: JSON.stringify(this.configInfo) };
      // this.$router.push({
      //   path: "/ScanCode",
      //   query: queryParams,
      // });
    },
    scanCodeTwo() {
      this.$router.push("/ScanCodePage");
    },
    getData() {
      //localStorage.setItem('meterName', this.$route.query.meterName);
      //localStorage.setItem('loadName', this.$route.query.loadName);
      sessionStorage.setItem("userName", this.$route.query.userName);
      //this.meterName = localStorage.getItem('meterName');
      //this.loadName = localStorage.getItem('loadName');
      this.userName = sessionStorage.getItem("userName");
      console.log(this.userName);
    },
    getWeChatCode: function () {
      //console.log('地址',window.location.href);
      let local = encodeURIComponent(window.location.href);
      //let local = encodeURIComponent('https://zy.methodmore.com/');
      // let appid = "wx45815ba17e811ada";
      let appid = "wx7e37bf260005d884";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        local +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    getUrlKeyVal: function (key) {
      //提取地址中的参数
      let r = new RegExp("[?|&]" + key + "=" + "([^&;]+?)(&|#|;|$)");
      console.log("r", r);
      let arr = r.exec(location.href) || [""];
      if (typeof arr[1] !== "undefined" && typeof arr[1] === "string") {
        let s = arr[1].replace(/\+/g, "%20");
        return decodeURIComponent(s) || null;
      } else {
        console.log("arr为空");
      }
    },
    postWechatCode() {
      let code = this.getUrlKeyVal("code");
      console.log(code);
      if (code && code != null) {
        axiosAPI({
          method: "post",
          url: "https://cz.methodmore.com:8099/WechatUser/WriteUserOpenId",
          params: { code: code },
        }).then((response) => {
          console.log(response);
        });
      } else {
        this.getWeChatCode();
      }
    },
    connctReport() {
      console.log("当前项目工程配置信息:", this.configInfo);
      //   axiosAPI({
      //     method: "post",
      //     url: "https://cz.methodmore.com:8099/projectConfig/InsertProjectConfig",
      //     params: this.configInfo,
      //   }).then((response) => {
      //     console.log(response);
      //   });
      //   axiosAPI({
      //     method: "get",
      //     url: "https://cz.methodmore.com:8099/ProjectConfig/SearchProjectConfigByScanCode",
      //     params: this.configInfo,
      //   }).then((response) => {
      //     console.log(response);
      //     if (response.data.result.bindInfo.bindStatus == "1") {
      //       showNotify({ type: "danger", message: "当前项目未解绑，请先解绑！" });
      //     } else {
      const queryParams = { params: JSON.stringify(this.configInfo) };
      this.$router.push({
        path: "/ConnectInfo",
        query: queryParams,
      });
      //  }
      // });
    },
    removeReport() {
      this.$router.push({
        path: "/ConnectInfo",
        query: { userName: this.userName, type: 1 },
      });
    },
  },
};
</script>

<style scoped>
body {
  width: 100%;
  min-width: 280px;
  max-width: 640px;
  margin: 0 auto;
  font-size: 14px;
  font-family: -apple-system, Helvetica, sans-serif;
  /* color: #666; */
  line-height: 1.5;
}
.title h2 {
  font-weight: 400;
  background-color: #fbfbfb;
  margin: 1.5rem;
  text-align: center;
}
.content {
  margin-bottom: 10px;
}
.metername,
.loadname,
.ele,
.remainEle {
  height: 8vh;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
.metername label,
.loadname label {
  display: block;
  margin: 10px 0;
}
.recharge {
  height: 30vh;
  margin-left: 2.5rem;
}
.recharge h3 {
  font-weight: 400;
  margin-top: 1rem;
}
.sname select,
.ele select {
  display: block;
  width: 90%;
  height: 5vh;
  border: 1.5px solid #ccc;
  border-radius: 8px;
}
/* .chooseMoney{
    width: 100%;
    height: 100px;
} */
.chooseMoney ul {
  float: left;
  padding-left: 2rem;
  margin-bottom: 1.6rem;
  margin-top: 1.5rem;
}
.chooseMoney ul li {
  list-style: none;
  float: left;
  width: 4rem !important;
  height: 6vh;
  margin: 0 5vh 2.5vh 0;
  cursor: pointer;
  line-height: 6vh;
  text-align: center;
}
.chooseMoney .confirmMoneybtn {
  background-color: #f2f2f2;
  color: #06ae56;
  font-weight: 800;
  border-radius: 8px;
}
.rechargeMoney {
  width: 90%;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ccc;
}
.rechargeMoney .icon {
  font-weight: 700;
  font-size: 16px;
}
.meterscan input,
.loadscan input,
.rechargeMoney input {
  border: 1px solid #ccc;
  width: 85%;
  height: 5vh;
  border-radius: 1rem;
  padding-left: 10px;
  font-size: 16px;
}

.recharge_btn {
  /* margin: 2rem 2.5rem 3rem; */
  margin: 2rem auto;
}
.weui-btn {
  width: 300px !important;
}

/* 用户维护样式 */
.userMsg {
  height: 20vh;
  text-align: center;
}
.userMsg label {
  display: block;
  font-size: 14px;
}
.userMsg input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* display: inline-block; */
  width: 50%;
  height: 5vh;
  margin: 20px 0;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding-left: 7px;
  outline: 0;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.phone {
  margin-top: 20px;
}
.submitMsg {
  border-radius: 8px;
  /* margin-left: -7px; */
  background-color: #f2f2f2;
  width: 160px;
  margin: auto;
}
.submitMsg .confirmUserInfo {
  color: #06ae56;
  position: relative;
  display: block;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 24px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  text-decoration: none;
  line-height: 1.88235294;
  border-radius: 8px;
  margin-top: 5vh;
}
.other {
  font-weight: 700;
  text-align: center;
  margin-top: 5vh;
}
.other a {
  color: #ccc;
}
.other a:hover {
  color: blue;
}
.box {
  margin-top: 20%;
}
.username,
.password,
.meterscan,
.loadscan {
  position: relative;
}
.loginIcon {
  font-size: 20px;
  color: #ccc;
  position: absolute;
  top: 29%;
  right: 26%;
  z-index: 2;
}
.viewIcon {
  font-size: 20px;
  color: #ccc;
  position: absolute;
  top: 7%;
  right: 15%;
  z-index: 2;
}
.custom-row-class {
  height: 10vh; /* 你想要的行高 */
}
</style>
